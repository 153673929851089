import {useEffect, useState, useCallback, useMemo} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {
  Box,
  Grid,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Divider,
  Tooltip,
} from '@mui/material';
import {Skeleton} from '@mui/lab';
import {ActionTab, AutoComplete} from 'shared/components';
import {useTitle} from 'shared/hooks';
import {confirm, feedback} from 'shared/services';
import {
  EstabelecimentosService,
  IEstablishmentTree,
} from 'shared/services/api/estabelecimentos/Estabelecimentos';
import {
  IGrupoPapeis,
  GrupoPapeisService,
} from 'shared/services/api/grupo-papeis/GrupoPapeisList';
import {
  IPublicoAlvoDetail,
  PublicoAlvoDetailsService,
} from 'shared/services/api/publico-alvo/PublicoAlvoDetail';
import {EstabSelectionTree} from './establishment/EstablishmentSelectionTree';
import {EstabSelectionTreeSkeleton} from './establishment/EstabSelectionTreeSkeleton';

interface IStepProps {
  idPublicoAlvo: string;
  setIdPublicoAlvo: (idPublicoAlvo: string) => void;
  nextStep: () => void;
  lastStep: () => void;
  onCheckRecargaAutomatica: (checked: boolean) => void;
  onCheckPublicoSemDisciplinas: (checked: boolean) => void;
  onCheckReprocessamento: (checked: boolean) => void;
  setHasNecessaryData: (hasNecessaryData: boolean) => void;
}

export const Configuracoes: React.FC<IStepProps> = ({
  idPublicoAlvo,
  setIdPublicoAlvo,
  nextStep,
  lastStep,
  onCheckRecargaAutomatica,
  onCheckPublicoSemDisciplinas,
  onCheckReprocessamento,
  setHasNecessaryData,
}) => {
  const {setTitle} = useTitle();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [publicoAlvo, setPublicoAlvo] = useState<IPublicoAlvoDetail>({
    Nome: '',
    Estabelecimentos: [] as string[],
    PermiteEdicao: true,
    RecargaAutomatica: true,
    ReprocessamentoAutomatico: false,
    ErpFonte: 'GVQUEST',
    IdsGruposPapeis: [] as string[],
  } as unknown as IPublicoAlvoDetail);
  const [treeEstabs, setTreeEstabs] = useState<IEstablishmentTree[]>([]);
  const [grupoPapeis, setGrupoPapeis] = useState<IGrupoPapeis[]>([]);
  const [selectedEstablishments, setSelectedEstablishments] = useState<
    string[]
  >([]);
  const [isValidatingFields, setIsValidatingFields] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [ERP, setERP] = useState<string>('');

  const goToLastStep = useMemo(() => {
    const location = window.location.href;
    const params = new URL(location).searchParams;
    return params.get('lastStep') ? true : false;
  }, []);

  useEffect(() => {
    if (publicoAlvo && treeEstabs.length && grupoPapeis.length) {
      setIsLoading(false);
    }
  }, [treeEstabs, grupoPapeis, publicoAlvo, location]);

  useEffect(() => {
    setIsLoading(true);
    EstabelecimentosService.getAllTreeFormat().then((result) => {
      if (result.Success) {
        setTreeEstabs(result.Data);
      } else {
        feedback('Erro ao carregar estabelecimentos...', 'error');
      }
    });

    GrupoPapeisService.getAll().then((data) => {
      if (data.Success) {
        setGrupoPapeis(data.Data);
        setERP(String(data.ERP));
      } else {
        feedback(data.Message, 'error');
      }
    });

    if (idPublicoAlvo !== 'new') {
      PublicoAlvoDetailsService.getById(idPublicoAlvo).then((data) => {
        if (data.Success) {
          setSelectedEstablishments(data.Data.Estabelecimentos);
          setPublicoAlvo(data.Data);
          setERP(data.Data.ErpFonte);
          onCheckRecargaAutomatica(data.Data.RecargaAutomatica);
          onCheckPublicoSemDisciplinas(data.Data.PublicoSemDisciplinas);
          onCheckReprocessamento(data.Data.ReprocessamentoAutomatico);
          setIsLoading(false);
        } else {
          feedback(data.Message || 'Erro ao carregar configurações.', 'error');
          setIsLoading(false);
        }
      });
    }

    if (goToLastStep) {
      lastStep();
    }
  }, [
    location,
    setTitle,
    lastStep,
    goToLastStep,
    idPublicoAlvo,
    onCheckRecargaAutomatica,
    onCheckPublicoSemDisciplinas,
    onCheckReprocessamento,
    setERP,
    ERP,
  ]);

  const handleBack = useCallback(() => {
    const handleConfirm = () => {
      navigate('/publico-alvo');
    };

    confirm(
      'Você tem certeza que deseja cancelar a configuração deste público-alvo?',
      'confirmation',
      handleConfirm,
    );
  }, [navigate]);

  const isEqualsIdGrupo = (IdGrupoPapel: string) => {
    const foundIndex = publicoAlvo.IdsGruposPapeis.findIndex(
      (value) => value === IdGrupoPapel,
    );

    if (foundIndex !== -1) {
      return true;
    }
    return false;
  };

  const validateFields = useCallback(() => {
    setIsValidatingFields(true);
    if (publicoAlvo.Nome === '') {
      feedback('Informe o nome o público-alvo.', 'error');
      return false;
    } else if (!publicoAlvo.Estabelecimentos.length) {
      feedback('Informe os estabelecimentos do público-alvo.', 'error');
      return false;
    } else if (!publicoAlvo.IdsGruposPapeis.length) {
      feedback('Informe os grupos de papéis do público-alvo.', 'error');
      return false;
    }
    setIsValidatingFields(false);
    return true;
  }, [
    publicoAlvo.Nome,
    publicoAlvo.Estabelecimentos,
    publicoAlvo.IdsGruposPapeis.length,
  ]);

  const handleSaveAndNext = useCallback(() => {
    const necessaryData = grupoPapeis
      .filter(
        (item) =>
          item.NomeInterno === 'aluno' ||
          item.NomeInterno === 'professor' ||
          item.NomeInterno === 'pai' ||
          item.NomeInterno === 'mae' ||
          item.NomeInterno === 'responsavelfinanceiro' ||
          item.NomeInterno === 'responsaveleducacional' ||
          item.NomeInterno === 'responsavelcontrato',
      )
      .map((item) => item.IdGrupoPapel);

    const hasNecessaryData = necessaryData.some((item) =>
      publicoAlvo.IdsGruposPapeis.includes(item),
    );

    if (validateFields()) {
      if (idPublicoAlvo === 'new') {
        PublicoAlvoDetailsService.post(publicoAlvo)
          .then((data) => {
            if (data.Success) {
              setIdPublicoAlvo(data.Data.Id);

              if (hasNecessaryData) {
                setHasNecessaryData(true);
                nextStep();
              } else {
                setHasNecessaryData(false);
                setOpenDialog(true);
              }
            } else {
              feedback(
                data.Message || 'Erro ao salvar filtros para o público-alvo.',
                'error',
              );
            }
          })
          .catch((error) =>
            feedback(
              error.Message || 'Erro ao salvar filtros para o público-alvo.',
              'error',
            ),
          );
      } else {
        PublicoAlvoDetailsService.put(publicoAlvo)
          .then((data) => {
            if (data.Success) {
              if (hasNecessaryData) {
                setHasNecessaryData(true);
                nextStep();
              } else {
                setHasNecessaryData(false);
                setOpenDialog(true);
              }
            } else {
              feedback(
                data.Message || 'Erro ao salvar filtros para o público-alvo.',
                'error',
              );
            }
          })
          .catch((error) =>
            feedback(
              error.Message || 'Erro ao salvar filtros para o público-alvo.',
              'error',
            ),
          );
      }
    }
  }, [
    nextStep,
    publicoAlvo,
    grupoPapeis,
    idPublicoAlvo,
    validateFields,
    setIdPublicoAlvo,
    setHasNecessaryData,
  ]);

  const handleStartBackgroundProcess = useCallback(
    (idPublicoAlvo: string) => {
      setIsLoading(true);
      PublicoAlvoDetailsService.startProcessing(idPublicoAlvo).then(() => {
        setIsLoading(false);
        navigate('/publico-alvo');
      });
    },
    [navigate],
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box marginY={2}>
            <Typography variant="h5" color="primary">
              <strong>Configurações</strong>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box width="100%" marginBottom={2}>
            <Typography variant="subtitle1">
              <strong>Identificação</strong>
            </Typography>
          </Box>
          {isLoading ? (
            <Skeleton
              style={{borderRadius: '4px'}}
              variant="rectangular"
              width="100%"
              height={56}
            />
          ) : (
            <TextField
              fullWidth
              disabled={!publicoAlvo.PermiteEdicao}
              label="Descrição *"
              variant="outlined"
              value={publicoAlvo.Nome}
              error={isValidatingFields && publicoAlvo.Nome === ''}
              helperText={
                isValidatingFields &&
                publicoAlvo.Nome === '' &&
                'Este campo é obrigatório.'
              }
              onChange={({target}) => {
                setPublicoAlvo(
                  (state) =>
                    (state = {
                      ...state,
                      Nome: target.value,
                    }),
                );
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <Skeleton variant="text" width="75%" height={35} />
          ) : (
            <>
              <div>
                <Tooltip title="Caso a opção esteja desmarcada, o sistema registrará uma lista fixa de pessoas e, mesmo que alguém perca o vinculo com a IE, esta fará parte do público-alvo. O contrário também é válido. Se um novo aluno for matriculado em um dos cursos que compõem o público-alvo, este não fará parte da lista de pessoas do público. A opção é marcada por padrão.">
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!publicoAlvo.PermiteEdicao}
                        color="primary"
                        checked={publicoAlvo.RecargaAutomatica}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setPublicoAlvo((state) => ({
                            ...state,
                            RecargaAutomatica: e.target.checked,
                          }));
                          onCheckRecargaAutomatica(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <strong>
                        Recarregar a lista de pessoas automaticamente com base
                        nos filtros informados
                      </strong>
                    }
                  />
                </Tooltip>
              </div>
              {ERP === 'GVcollege' ? (
                <div>
                  <Tooltip title="Funcionalidade Exclusiva do ERP Centris">
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={ERP === 'GVcollege'}
                          color="primary"
                          checked={publicoAlvo.PublicoSemDisciplinas}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setPublicoAlvo((state) => ({
                              ...state,
                              PublicoSemDisciplinas: e.target.checked,
                            }));
                            onCheckPublicoSemDisciplinas(e.target.checked);
                          }}
                        />
                      }
                      label={
                        <strong>
                          Habilitar Público-Alvo sem disciplinas e turmas
                        </strong>
                      }
                    />
                  </Tooltip>
                </div>
              ) : (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={ERP === 'GVcollege'}
                        color="primary"
                        checked={publicoAlvo.PublicoSemDisciplinas}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setPublicoAlvo((state) => ({
                            ...state,
                            PublicoSemDisciplinas: e.target.checked,
                          }));
                          onCheckPublicoSemDisciplinas(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <strong>
                        Habilitar Público-Alvo sem disciplinas e turmas
                      </strong>
                    }
                  />
                </div>
              )}
            </>
          )}
          {ERP === 'GVcollege' ? (
            <>
              {isLoading ? (
                <Skeleton variant="text" width="75%" height={35} />
              ) : (
                <>
                  <Tooltip title="Ao marcar esta opção, será reprocessado de forma automática (de acordo com a configuração do agendamento) o público-alvo, atualizando a listagem de pessoas caso alguma pessoa nova seja incluída.">
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!publicoAlvo.PermiteEdicao}
                          color="primary"
                          checked={publicoAlvo.ReprocessamentoAutomatico}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setPublicoAlvo(
                              (state) =>
                                (state = {
                                  ...state,
                                  ReprocessamentoAutomatico: e.target.checked,
                                }),
                            );
                            onCheckReprocessamento(e.target.checked);
                          }}
                        />
                      }
                      label={<strong>Reprocessamento automático</strong>}
                    />
                  </Tooltip>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>

        <Box width="100%" paddingX={1.75} paddingTop={2}>
          <Typography variant="subtitle1">
            <strong>Papeís</strong>
          </Typography>
        </Box>
        <Grid item xs={12}>
          {isLoading ? (
            <Skeleton
              style={{borderRadius: '4px'}}
              variant="rectangular"
              width="100%"
              height={56}
            />
          ) : (
            <AutoComplete
              multiple
              disabled={!publicoAlvo.PermiteEdicao}
              options={grupoPapeis}
              value={grupoPapeis.filter((grupoPapel) =>
                isEqualsIdGrupo(grupoPapel.IdGrupoPapel),
              )}
              isOptionEqualToValue={(option, value) =>
                option.IdGrupoPapel === value.IdGrupoPapel
              }
              getOptionLabel={(option) => option.Nome}
              filterSelectedOptions
              onChange={(_, value) => {
                const selected = value.map((i: IGrupoPapeis) => i.IdGrupoPapel);
                setPublicoAlvo(
                  (state) =>
                    (state = {...publicoAlvo, IdsGruposPapeis: selected}),
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Grupo de papéis *"
                  error={
                    isValidatingFields && !publicoAlvo.IdsGruposPapeis.length
                  }
                  helperText={
                    isValidatingFields &&
                    !publicoAlvo.IdsGruposPapeis.length &&
                    'Este campo é obrigatório.'
                  }
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Box width="100%" marginBottom={2}>
            <Typography variant="subtitle1">
              <strong>Estabelecimentos</strong>
            </Typography>
          </Box>
          {isLoading ? (
            <EstabSelectionTreeSkeleton />
          ) : (
            <EstabSelectionTree
              selectedEstablishments={selectedEstablishments}
              establishments={treeEstabs}
              onChangeEstabs={(selectedEstabs) => {
                setPublicoAlvo({
                  ...publicoAlvo,
                  Estabelecimentos: selectedEstabs,
                } as IPublicoAlvoDetail);
              }}
            />
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        fullWidth
        maxWidth="md"
        aria-labelledby="modal-detail">
        <DialogTitle>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyItems={'space-between'}>
            <Typography color="primary" variant="h6">
              Filtros salvos com sucesso!
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="subtitle1">
            Estamos realizando a busca das pessoas. Agora, você será
            redirecionado para a página inicial do Público-alvo. Enviaremos uma
            notificação ao concluir esse processo.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            color="primary"
            variant="contained"
            onClick={() => {
              handleStartBackgroundProcess(idPublicoAlvo);
            }}>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      <ActionTab
        onSaveLabel="Avançar"
        onCancel={handleBack}
        onSave={isLoading ? () => false : handleSaveAndNext}
      />
    </>
  );
};
